import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react';

import "./App.css";
import Navbar from "./Components/Navbar";
import Homedepartment from "./Components/Homedepartment";
import Homeadavntage from "./Components/Homeadavntage";
import Homeadvanbelow from "./Components/Homeadvanbelow";
import Homecounterup from "./Components/Homecounterup";
import Homecollab from "./Components/Homecollab";
import Homeeducationfor from "./Components/Homeeducationfor";
import Hometopfaculty from "./Components/Hometopfaculty";
import Homegetlatest from "./Components/Homegetlatest";
import Footer from "./Components/Footer";
import Fundanibanner from "./Components/Fundamentalanima/Fundanibanner";
import Fundanifooter from "./Components/Fundamentalanima/Fundanifooter";
import Enterbanner from "./Components/Entrepreneurship/Enterbanner";
import Graphybanner from "./Components/Videography/Graphybanner";
import Visualbanner from "./Components/VisualDevelopment/Visualbanner";
import Motionbanner from "./Components/Motiondesign/Motionbanner";
import Architecbanner from "./Components/Architecture Visualization/Architecbanner";
import Advancedigibanner from "./Components/Advancedigitalmarket/Advancedigibanner";
import Socialmediabanner from "./Components/Socialmediamarket/Socialmediabanner";
import Abaout from "./Components/Abaout";
import Leadership from "./Components/Leadership";
import VissionMission from "./Components/VissionMission";
import Yogabanner from "./Components/Yoga/Yogabanner";
import Riskbanner from "./Components/Riskmangement/Riskbanner";
import Dietbanner from "./Components/Diet&Nutrition/Dietbanner";
import Contactus from "./Components/Contactus";
import Privacypolicy from "./Components/Privacypolicy";
import Becomeaninstructor from "./Components/Becomeaninstructor";
import HomeReasonsToJoin from "./Components/HomeReasonsToJoin";
import Fundamenbanner from "./Components/Fundamentaldigimarket/Fundamenbanner";
import Poups from "./Components/Poups";
import Sliders from "./Components/Sliders";
import Notices from "./Notices";
import Menues from "./Menues";
import HomeCoursePopular from "./Components/HomeCoursePopular";
import AnimationCourse from "./Components/AnimationCourse";
import MarketCourse from "./Components/MarketCourse";
import EntrepreneurshipCourse from "./Components/EntrepreneurshipCourse";
import CourseYoga from "./Components/CourseYoga";
import Form from "./Components/Form";
import Brochureenquiry from "./Components/Brochureenquiry";
import Popup from "./Components/Popup";
import Dummypop from "./Components/Dummypop";
import HomeExploreProgram from "./Components/HomeExploreProgram";
import HomeBannerII from "./Components/HomeBannerII";
import AllCoursepage from "./Components/AllCoursepage";
import Homestudenttestemonial from "./Components/Homestudenttestemonial";
import Homeourlearnerswork from "./Components/Homeourlearnerswork";
import Homecontactsupport from "./Components/Homecontactsupport";
import Homecorecredentials from "./Components/Homecorecredentials";
import Banner from "./Components/Aiindigitalmarketing/Banner";
import HomeBatchTime from "./Components/HomeBatchTime";
import Pouup1 from "./Components/Pouup1";
import DateComponent from "./Components/DateComponent";
import Faq from "./Components/Faq";
import PageMeta from "./Components/PageMeta";
function App() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);
  return (
    <>
      <Router>
      {showPopup && <Pouup1 onClose={() => setShowPopup(false)} />}
        <Menues />
        <Navbar />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
              <PageMeta
          title="Maharishi University Online (MUIT Online) | Best Online Course"
          description="Enroll in Maharishi University Online (MUIT Online), India’s best university, which provides the best online certification programs with 500+ expert faculty members. Best Online University For Online Digital Marketing Courses, Online 3d Animation Courses, Online Entrepreneurship courses, Diet and Nutrition, and Online Yoga Certification."
          keywords="Maharishi University Online, MUIT Online, MUIT Online Certifications, MUIT Online Courses, MUIT Online University, MUIT Online Noida"
          ogTitle="Maharishi University Online (MUIT Online) | Best Online Course"
          ogDescription="Enroll in Maharishi University Online (MUIT Online), India’s best university, which provides the best online certification programs with 500+ expert faculty members. Best Online University For Online Digital Marketing Courses, Online 3d Animation Courses, Online Entrepreneurship courses, Diet and Nutrition, and Online Yoga Certification."
          ogImage="https://muitonline.com/assets/images/logo/logo.png"
           />
                {" "}
              <Notices /> <HomeBannerII /> <HomeExploreProgram />{" "}
                <Homeeducationfor /> <HomeBatchTime /> <Homeourlearnerswork />{" "}
                <Homedepartment /> <Homecontactsupport /> <Homeadavntage />
                <Homeadvanbelow /> <Homecounterup /> <Homecorecredentials />
                <HomeReasonsToJoin />
                <HomeCoursePopular />
                <Hometopfaculty /><Faq/><Homecollab />
                <Homegetlatest />
              </>
            }
          ></Route>
          <Route
            exact
            path="/Dummypop"
            element={
              <>
                {" "}
                <Dummypop />
              </>
            }
          ></Route>
          <Route
            exact
            path="/AnimationCourse"
            element={
              <>
                {" "}
                <AnimationCourse />
              </>
            }
          ></Route>
          <Route
            exact
            path="/DateComponent"
            element={
              <>
                {" "}
                <DateComponent/>
              </>
            }
          ></Route>
          <Route
            exact
            path="/all-course"
            element={
              <>
                {" "}
                <AllCoursepage />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Brochure"
            element={
              <>
                {" "}
                <Brochureenquiry />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Form"
            element={
              <>
                {" "}
                <Form />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Popup"
            element={
              <>
                {" "}
                <Popup />
              </>
            }
          ></Route>

          <Route
            exact
            path="/CourseYoga"
            element={
              <>
                {" "}
                <CourseYoga />
              </>
            }
          ></Route>

          <Route
            exact
            path="/MarketCourse"
            element={
              <>
                {" "}
                <MarketCourse />
              </>
            }
          ></Route>

          <Route
            exact
            path="/EntrepreneurshipCourse"
            element={
              <>
                {" "}
                <EntrepreneurshipCourse />
              </>
            }
          ></Route>

          <Route
            exact
            path="/About"
            element={
              <>
               <PageMeta
          title="Maharishi University Online (MUIT Online) | Best Online Course"
          description="Maharishi University Online (MUIT Online) provides various online courses, including Digital Marketing Course Online, 3D Animation, Entrepreneurship, Diet and Nutrition, and Yoda Certification. It offers flexible, accessible education tailored to modern learning requirements, allowing students to gain skills in various fields at their own pace."
          keywords="Maharishi University Online, MUIT Online, MUIT Online Certifications, MUIT Online Courses, MUIT Online University, MUIT Online Noida"
          ogTitle="Maharishi University Online (MUIT Online) | Best Online Course"
          ogDescription="Maharishi University Online (MUIT Online) provides various online courses, including Digital Marketing Course Online, 3D Animation, Entrepreneurship, Diet and Nutrition, and Yoda Certification. It offers flexible, accessible education tailored to modern learning requirements, allowing students to gain skills in various fields at their own pace."
          ogImage="https://muitonline.com/assets/images/logo/logo.png"
           />
                {" "}
               <Abaout />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Contactus"
            element={
              <>
              <PageMeta
          title="Maharishi University Online Helpline Number | MUIT Online Helpline Number"
          description="Maharishi University Online is the best university for online certification courses with 100% job assistance. Maharishi University Online, MUIT Online Helpline Number"
          keywords="Maharishi University Online Helpline Number, MUIT Online Helpline Number, MUIT Online Contact Number, MUIT Online Email, MUIT Online University, MUIT Online Noida"
          ogTitle="Maharishi University Online Helpline Number | MUIT Online Helpline Number"
          ogDescription="Maharishi University Online is the best university for online certification courses with 100% job assistance. Maharishi University Online, MUIT Online Helpline Number"
          ogImage="https://muitonline.com/assets/images/logo/logo.png"
           />
                {" "}
                <Contactus />
              </>
            }
          ></Route>



          <Route
            exact
            path="/Leadership"
            element={
              <>
                {" "}
                <Leadership />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Vision&Mission"
            element={
              <>
                {" "}
                <VissionMission />
              </>
            }
          ></Route>

          <Route
            exact
            path="/fundamental-of-3D-animation"
            element={
              <>
               <PageMeta
          title="3D Animation Courses | Maharishi University Online"
          description="Want to join computer and 3D animation online courses after class 12? Maharishi University Online offers career-building 3D animation courses Online. Sharpen your skills and make your mark in the 3d animation industry."
          keywords="3D Animation Courses, 3D animation course Online, Computer animation course Online, 3d animation certificate online, 3D animation course online, 3d design courses online"
          ogTitle="3D Animation Courses | Maharishi University Online"
          ogDescription="Want to join computer and 3D animation online courses after class 12? Maharishi University Online offers career-building 3D animation courses Online. Sharpen your skills and make your mark in the 3d animation industry."
          ogImage="https://muitonline.com/assets/images/course/c4.jpg"
        />
                {" "}
                <Fundanibanner />
                <Fundanifooter />
              </>
            }
          ></Route>
          <Route
            exact
            path="/Entrepreneurship"
            element={
              <>
              <PageMeta
          title="Entrepreneurship Course Online | Maharishi University Online"
          description="Learn essential skills to start and grow your own business with our comprehensive Online Entrepreneurship Course. Gain practical knowledge, expert insights, and real-world experience. Enroll today!"
          keywords="Entrepreneurship Course, Entrepreneurship Course Online, Entrepreneurship Course certificate, online Entrepreneurship Course, Fundamentals of Entrepreneurship Course, Entrepreneurship Course classes, best Entrepreneurship Course"
          ogTitle="Entrepreneurship Course Online | Maharishi University Online"
          ogDescription="Learn essential skills to start and grow your own business with our comprehensive Online Entrepreneurship Course. Gain practical knowledge, expert insights, and real-world experience. Enroll today!"
          ogImage="https://muitonline.com/assets/images/logo/logo.png"
           />
                {" "}
                <Enterbanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Videography"
            element={
              <>
                {" "}
                <Graphybanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/certification-in-maharishi-yoga"
            element={
              <>
               <PageMeta
          title="Certification in Yoga Course Online | Maharishi University Online"
          description="Earn a Certification in Yoga Online with our comprehensive course. Learn from professional educators, deepen your practice, and enhance your teaching skills from the comfort of your home."
          keywords="Certification in Yoga Course Online, Yoga Course Online, online yoga classes, online yoga teacher training, best online yoga classes, yoga certification online"
          ogTitle="Certification in Yoga Course Online | Maharishi University Online"
          ogDescription="Earn a Certification in Yoga Online with our comprehensive course. Learn from professional educators, deepen your practice, and enhance your teaching skills from the comfort of your home."
          ogImage="https://muitonline.com/assets/images/logo/logo.png"
           />
                {" "}
                <Yogabanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/maharishi-diet-and-nutrition"
            element={
              <>
              <PageMeta
          title="Diet & Nutrition (Dietician) Course Online | Maharishi University Online"
          description="Learn everything you need to know about Diet & Nutrition with Maharishi University Online's comprehensive online courses. Earn your online certificate and join the field today! Enroll today!"
          keywords="Diet & Nutrition Course, Diet & Nutrition Course Online, Nutritionist Course Online, Online Nutritionist Certification Online, Dietician Course Online, Dietician Online Course, best Diet & Nutrition Course, Online Courses for Dietician"
          ogTitle="Diet & Nutrition (Dietician) Course Online | Maharishi University Online"
          ogDescription="Learn everything you need to know about Diet & Nutrition with Maharishi University Online's comprehensive online courses. Earn your online certificate and join the field today! Enroll today!"
          ogImage="https://muitonline.com/assets/images/logo/logo.png"
           />
                {" "}
                <Dietbanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Risk-Management"
            element={
              <>
                {" "}
                <Riskbanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/visual-development-concept-art-advanced"
            element={
              <>
              <PageMeta
          title="Online Visual Development/Concept Art Advanced | MUIT Online"
          description="Earn your online visual development degree from Maharishi University Online. Be part of our vibrant, creative community."
          keywords="Visual Development, Online Visual Development Degree, Concept art advanced course, Visual Development Certificate, Animation Visual Development Online"
          ogTitle="Online Visual Development/Concept Art Advanced | MUIT Online"
          ogDescription="Earn your online visual development degree from Maharishi University Online. Be part of our vibrant, creative community."
          ogImage="https://muitonline.com/assets/images/course/c42.jpg"
        />
                {" "}
                <Visualbanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/motion-design-for-graphic-designers"
            element={
              <>
              <PageMeta
          title="Motion Design for Graphic Designers Online Course | MUIT Online"
          description="Our Motion Design for Graphic Designers Online Course is organized to teach you in the fundamentals, techniques, and trends required to succeed in the digital media industry."
          keywords="Motion Design for Graphic Designers Online Course, motion graphics course, motion graphics online courses, motion graphics certificate online, motion design online course, learn motion graphics online"
          ogTitle="Motion Design for Graphic Designers Online Course | MUIT Online"
          ogDescription="Our Motion Design for Graphic Designers Online Course is organized to teach you in the fundamentals, techniques, and trends required to succeed in the digital media industry."
          ogImage="https://muitonline.com/assets/images/logo/logo.png"
           />
                {" "}
                <Motionbanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Architecture-visualization"
            element={
              <>
               <PageMeta
          title="Architecture Visualization Online Course | Maharishi University Online"
          description="The Online Architecture Visualization course teaches digital modeling, rendering, and presentation techniques for architectural projects, enhancing design communication through software like SketchUp, Rhino, and V-Ray."
          keywords="Architecture Visualization Course, Architecture Visualization Online Course, Architectural Visualization Courses Online, Architectural Visualization Certificate"
          ogTitle="Architecture Visualization Online Course | Maharishi University Online"
          ogDescription="The Online Architecture Visualization course teaches digital modeling, rendering, and presentation techniques for architectural projects, enhancing design communication through software like SketchUp, Rhino, and V-Ray."
          ogImage="https://muitonline.com/assets/images/logo/logo.png"
           />
                {" "}
                <Architecbanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/advance-digital-marketing-course"
            element={
              <>
              <PageMeta
          title="Advanced Digital Marketing Course Online | Maharishi University Online"
          description="Become an Advanced Digital Marketing Expert and start learning from fundamentals! Strong Advanced digital marketing online course fundamentals are significant to learn!"
          keywords="Advanced digital Marketing Course, advanced digital Marketing Course Online, advanced digital marketing, best advanced digital marketing course, fundamentals of advanced digital marketing, digital marketing course free, advanced digital marketing certification"
          ogTitle="Advanced Digital Marketing Course Online | Maharishi University Online"
          ogDescription="Become an Advanced Digital Marketing Expert and start learning from fundamentals! Strong Advanced digital marketing online course fundamentals are significant to learn!"
          ogImage="https://muitonline.com/assets/images/logo/logo.png"
           />
                {" "}
                <Advancedigibanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Ai-in-digital-Marketing"
            element={
              <>
              <PageMeta
          title="Ai in Digital Marketing Course Online | Maharishi University Online"
          description="AI in Digital Marketing Course Online: Artificial Intelligence enhances digital marketing strategies and provides valuable customer insights for companies. So enroll now and build your career."
          keywords="AI in Digital Marketing Course, Ai Digital Marketing Course Online, Artificial Intelligence in Digital Marketing, Ai Digital Marketing Certificate, online AI Digital Marketing Courses, Artificial Intelligence in Digital Marketing Course, Ai in Digital Marketing Classes, best Ai Digital Marketing Courses"
          ogTitle="Ai in Digital Marketing Course Online | Maharishi University Online"
          ogDescription="AI in Digital Marketing Course Online: Artificial Intelligence enhances digital marketing strategies and provides valuable customer insights for companies. So enroll now and build your career."
          ogImage="https://muitonline.com/assets/images/logo/logo.png"
           />
                {" "}
                <Banner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/digital-marketing-course"
            element={
              <>
                {" "}
                <Fundamenbanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Social-Media-Marketing"
            element={
              <>
              <PageMeta
          title="Best Social Media Marketing Courses Online | Maharishi University Online"
          description="Our Social Media Marketing online training courses provide the skills you require, from the fundamentals to advanced tips. Browse our comprehensive preference of Social Media Marketing courses to find precisely what you want."
          keywords="Social Media Marketing Course, Social Media Marketing Course Online, Social Media Marketing certificate, online Social Media Marketing courses, fundamentals of Social Media Marketing, Social Media Marketing classes, best Social Media Marketing courses"
          ogTitle="Best Social Media Marketing Courses Online | Maharishi University Online"
          ogDescription="Our Social Media Marketing online training courses provide the skills you require, from the fundamentals to advanced tips. Browse our comprehensive preference of Social Media Marketing courses to find precisely what you want."
          ogImage="https://muitonline.com/assets/images/logo/logo.png"
           />
                {" "}
                <Socialmediabanner />
                <Fundanifooter />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Privacypolicy"
            element={
              <>
                {" "}
                <Privacypolicy />
              </>
            }
          ></Route>

          <Route
            exact
            path="/Becomeaninstructor"
            element={
              <>
                {" "}
                <Becomeaninstructor />
              </>
            }
          ></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
