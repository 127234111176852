import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BatchTime = () => {
  const [startDates, setStartDates] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBatchStartDates = async () => {
      try {
        const response = await axios.get(
          'https://mocrm.muituniversity.in/api/EduoApi/WebBatchstart'
        );
        setStartDates(response.data.data || []); // Extract the data field
      } catch (err) {
        setError('Failed to fetch data');
      }
    };

    fetchBatchStartDates();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate(); // Extract day
    const month = date.toLocaleString('default', { month: 'long' }); // Get full month name
    const year = date.getFullYear(); // Get the year
    return `${day} ${month} ${year}`; // Return date in '1 October 2025' format
  };

  return (
    <div>
     
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <ul>
        {startDates.map((batch, index) => (
          <li key={index}>
            {batch.StartDate
              ? ` ${formatDate(batch.StartDate)}`
              : 'No Start Date Available'}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BatchTime;
