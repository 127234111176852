import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Contactus = () => {
  useEffect(() => {
    // Update the document title
    document.title = "Maharishi University Online Helpline Number | MUIT Online Helpline Number";

    // Update meta description
    let descMeta = document.querySelector("meta[name='description']");
    if (!descMeta) {
      descMeta = document.createElement('meta');
      descMeta.name = 'description';
      document.head.appendChild(descMeta);
    }
    descMeta.setAttribute(
      'content',
      'Maharishi University Online is the best university for online certification courses with 100% job assistance. Maharishi University Online, MUIT Online Helpline Number'
    );

    // Update meta keywords
    let keywordsMeta = document.querySelector("meta[name='keywords']");
    if (!keywordsMeta) {
      keywordsMeta = document.createElement('meta');
      keywordsMeta.name = 'keywords';
      document.head.appendChild(keywordsMeta);
    }
    keywordsMeta.setAttribute(
      'content',
      'Maharishi University Online Helpline Number, MUIT Online Helpline Number, MUIT Online Contact Number, MUIT Online Email, MUIT Online University, MUIT Online Noida'
    );
  }, 
  []);

  return (
    <>
      <Helmet>
      {/* Title */}
      <title>Maharishi University Online Helpline Number | MUIT Online Helpline Number</title>

      {/* Meta Description */}
      <meta
        name="description"
        content="Maharishi University Online is the best university for online certification courses with 100% job assistance. Maharishi University Online, MUIT Online Helpline Number"
      />

      {/* Meta Keywords */}
      <meta
        name="keywords"
        content="Maharishi University Online Helpline Number, MUIT Online Helpline Number, MUIT Online Contact Number, MUIT Online Email, MUIT Online University, MUIT Online Noida"
      />

      {/* Open Graph Tags */}
      <meta property="og:title" content="Maharishi University Online Helpline Number | MUIT Online Helpline Number" />
      <meta
        property="og:description"
        content="Maharishi University Online is the best university for online certification courses with 100% job assistance. Maharishi University Online, MUIT Online Helpline Number"
      />
      <meta property="og:url" content="https://muitonline.com/" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://muitonline.com/assets/images/logo/logo.png" />

      {/* JSON-LD Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Review",
          "itemReviewed": {
            "@type": "Organization",
            "name": "Maharishi University Online",
            "url": "https://muitonline.com/",
          },
          "author": {
            "@type": "Organization",
            "name": "Maharishi University Online",
          },
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5",
            "bestRating": "5",
            "worstRating": "1",
          },
          "reviewBody":
            "Maharishi University Online offers an outstanding range of courses with excellent faculty. The online platform is user-friendly and the course content is up-to-date and relevant. Highly recommended!",
        })}
      </script>
    </Helmet>

      <div className="rbt-conatct-area bg-gradient-11 rbt-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb--60">
                <span className="subtitle bg-coral-opacity">
                  Contact Us
                </span>
                <h2 className="title">
                  Maharishi University Online (MUIT Online)
                </h2>
                <p>We'd love to hear from you! If you have any questions, or feedback, or need assistance, please reach out to us.</p>
              </div>
            </div>
          </div>
          <div className="row g-5">
            <div
              className="col-lg-6 col-md-6 col-sm-6 col-12 sal-animate"
              data-sal="slide-up"
              data-sal-delay="150"
              data-sal-duration="800"
            >
              <div className="rbt-address">
                <div className="icon">
                  <i className="feather-headphones"></i>
                </div>
                <div className="inner">
                  <h4 className="title">MUIT Online Helpline Number</h4>
                  <p>
                    <a href="tel:+919599071023"><i className="fa fa-phone"></i> +91 9599071023</a>
                  </p>
                  <br />
                  <h4 className="title">Contact Phone Number</h4>
                  <p>
                    <a href="tel:+919311107230"><i className="fa fa-phone"></i> +91 93111 07230</a>, <a href="tel:+919560543311"><i className="fa fa-phone"></i> +91 95605 43311</a>
                  </p>
                  <br />
                  <h4 className="title">Whatsapp No</h4>
                  <p>
                    <a href="https://wa.me/919599071023" target="_blank" rel="noopener noreferrer"><i className="fa fa-whatsapp"></i> +91 9599071023</a>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-6 col-12 sal-animate"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="800"
            >
              <div className="rbt-address">
                <div className="icon">
                  <i className="feather-mail"></i>
                </div>
                <div className="inner">
                  <h4 className="title">Our Email Address</h4>
                  <p>
                    <a href="mailto:help@muitonline.com"><i className="fa fa-envelope"></i> help@muitonline.com</a>
                  </p>
                  <br />
                  <p>
                    <a href="mailto:hr@muitonline.com"><i className="fa fa-envelope"></i> hr@muitonline.com</a>
                  </p>
                  <br />
                  <h4 className="title">Address</h4>
                  <p>
                    <a href="https://www.google.com/maps?q=MAHARISHI+UNIVERSITY+OF+INFORMATION+TECHNOLOGY,+opp.+Sec+110,+Maharshi+Nagar,+Noida,+Uttar+Pradesh+201304" target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-map-marker"></i> MAHARISHI UNIVERSITY OF INFORMATION TECHNOLOGY, opp. Sec 110, Maharshi Nagar, Noida, Uttar Pradesh 201304
                    </a>
                  </p>
                  <br />
                  <h4 className="title">Business Hours</h4>
                  <p>Monday - Sunday: 9:00 AM - 8:00 PM</p>
                  <br />
                  <h4 className="title">Follow us on social media for updates and promotions:</h4>
                  <p>
                    <a href="https://www.facebook.com/muitonline" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i> Facebook</a> | 
                    <a href="https://twitter.com/muitonline" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i> Twitter</a> | 
                    <a href="https://www.instagram.com/muitonline/" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i> Instagram</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rbt-separator-mid">
        <div className="container">
          <hr className="rbt-separator m-0"></hr>
        </div>
      </div>
    </>
  );
};

export default Contactus;
