import React, { useState, useEffect } from 'react'
import Motionoverview from './Motionoverview'
import { Helmet } from 'react-helmet';
const Motionbanner = () => {
     useEffect(() => {
       // Update the document title
       document.title = "Motion Design for Graphic Designers Online Course | MUIT Online";
   
       // Update meta description
       let descMeta = document.querySelector("meta[name='description']");
       if (!descMeta) {
         descMeta = document.createElement('meta');
         descMeta.name = 'description';
         document.head.appendChild(descMeta);
       }
       descMeta.setAttribute(
         'content',
         'Our Motion Design for Graphic Designers Online Course is organized to teach you in the fundamentals, techniques, and trends required to succeed in the digital media industry.'
       );
   
       // Update meta keywords
       let keywordsMeta = document.querySelector("meta[name='keywords']");
       if (!keywordsMeta) {
         keywordsMeta = document.createElement('meta');
         keywordsMeta.name = 'keywords';
         document.head.appendChild(keywordsMeta);
       }
       keywordsMeta.setAttribute(
         'content',
         'Motion Design for Graphic Designers Online Course, motion graphics course, motion graphics online courses, motion graphics certificate online, motion design online course, learn motion graphics online'
       );
     }, []);
  return (
    <>
      <Helmet>
           {/* Title */}
           <title>Motion Design for Graphic Designers Online Course | MUIT Online</title>
     
           {/* Meta Description */}
           <meta
             name="description"
             content="Our Motion Design for Graphic Designers Online Course is organized to teach you in the fundamentals, techniques, and trends required to succeed in the digital media industry."
           />
     
           {/* Meta Keywords */}
           <meta
             name="keywords"
             content="Motion Design for Graphic Designers Online Course, motion graphics course, motion graphics online courses, motion graphics certificate online, motion design online course, learn motion graphics online"
           />
     
           {/* Open Graph Tags */}
           <meta property="og:title" content="Motion Design for Graphic Designers Online Course | MUIT Online" />
           <meta
             property="og:description"
             content="Our Motion Design for Graphic Designers Online Course is organized to teach you in the fundamentals, techniques, and trends required to succeed in the digital media industry."
           />
           <meta property="og:url" content="https://muitonline.com/" />
           <meta property="og:type" content="website" />
           <meta property="og:image" content="https://muitonline.com/assets/images/logo/logo.png" />
     
           {/* JSON-LD Structured Data */}
           <script type="application/ld+json">
             {JSON.stringify({
               "@context": "https://schema.org",
               "@type": "Review",
               "itemReviewed": {
                 "@type": "Organization",
                 "name": "Maharishi University Online",
                 "url": "https://muitonline.com/",
               },
               "author": {
                 "@type": "Organization",
                 "name": "Maharishi University Online",
               },
               "reviewRating": {
                 "@type": "Rating",
                 "ratingValue": "5",
                 "bestRating": "5",
                 "worstRating": "1",
               },
               "reviewBody":
                 "Maharishi University Online offers an outstanding range of courses with excellent faculty. The online platform is user-friendly and the course content is up-to-date and relevant. Highly recommended!",
             })}
           </script>
         </Helmet>
    <div className="rbt-breadcrumb-default rbt-breadcrumb-style-3">
        <div className="breadcrumb-inner">
            <img src="assets/images/bg/bg-image-10.jpg" alt="Education Images"/>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="content text-start">
                        <ul className="page-list">
                            <li className="rbt-breadcrumb-item"><a href="/">Home</a></li>
                            <li>
                                <div className="icon-right"><i className="feather-chevron-right"></i></div>
                            </li>
                            <li className="rbt-breadcrumb-item active">Motion Design for Graphic Designers</li>
                        </ul>
                        <h2 className="title"> <span className='textred'>Motion Design for Graphic Designers</span> </h2>
                        <p className="description"> Are you looking to upgrade your skills in animation for your career enhancement? This course is designed for all graphic designers who want to understand the constant change in the industry.</p>

                        <div className="d-flex align-items-center mb--20 flex-wrap rbt-course-details-feature">

                            <div className="feature-sin best-seller-badge">
                                <span className="rbt-badge-2">
                                    <span className="image"><img src="assets/images/icons/card-icon-1.png"
                                            alt="Best Seller Icon"/></span> online learning platform
                                </span>
                            </div>

                            <div className="feature-sin rating">
                                <a href="#">4.6</a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star-half-o"></i></a>
                            </div>

                            <div className="feature-sin total-rating">
                                <a className="rbt-badge-4" href="#">15,475 rating</a>
                            </div>

                            <div className="feature-sin total-student">
                                <span>16,029 students</span>
                            </div>

                        </div>

                        <div className="rbt-author-meta mb--20">
                            <div className="rbt-avater">
                                <a href="#">
                                    <img src="assets/images/team/team-03.jpg" alt="Ritesh Kumar Bhanu"/>
                                </a>
                            </div>
                            <div className="rbt-author-info">
                                By <a href="#">Ritesh Kumar Bhanu</a> In <a href="#">Motion Design for Graphic Designers</a>
                            </div>
                        </div>

                        <ul className="rbt-meta">
                            <li><i className="feather-calendar"></i>Last updated 07/2023</li>
                            <li><i className="feather-globe"></i>English/Hindi</li>
                            <li><i className="feather-award"></i>Certified Course</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <Motionoverview/>
    </>
  )
}

export default Motionbanner