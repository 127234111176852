import React, { useState, useEffect } from 'react'
import Yogaoverview from './Yogaoverview'
import { Helmet } from 'react-helmet';
const Yogabanner = () => {

    useEffect(() => {
      // Update the document title
      document.title = "Certification in Yoga Course Online | Maharishi University Online";
  
      // Update meta description
      let descMeta = document.querySelector("meta[name='description']");
      if (!descMeta) {
        descMeta = document.createElement('meta');
        descMeta.name = 'description';
        document.head.appendChild(descMeta);
      }
      descMeta.setAttribute(
        'content',
        'Earn a Certification in Yoga Online with our comprehensive course. Learn from professional educators, deepen your practice, and enhance your teaching skills from the comfort of your home.'
      );
  
      // Update meta keywords
      let keywordsMeta = document.querySelector("meta[name='keywords']");
      if (!keywordsMeta) {
        keywordsMeta = document.createElement('meta');
        keywordsMeta.name = 'keywords';
        document.head.appendChild(keywordsMeta);
      }
      keywordsMeta.setAttribute(
        'content',
        'Certification in Yoga Course Online, Yoga Course Online, online yoga classes, online yoga teacher training, best online yoga classes, yoga certification online'
      );
    }, []);
  
    return (
      <>
        <Helmet>
        {/* Title */}
        <title>Certification in Yoga Course Online | Maharishi University Online</title>
  
        {/* Meta Description */}
        <meta
          name="description"
          content="Earn a Certification in Yoga Online with our comprehensive course. Learn from professional educators, deepen your practice, and enhance your teaching skills from the comfort of your home."
        />
  
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Certification in Yoga Course Online, Yoga Course Online, online yoga classes, online yoga teacher training, best online yoga classes, yoga certification online"
        />
  
        {/* Open Graph Tags */}
        <meta property="og:title" content="Certification in Yoga Course Online | Maharishi University Online" />
        <meta
          property="og:description"
          content="Earn a Certification in Yoga Online with our comprehensive course. Learn from professional educators, deepen your practice, and enhance your teaching skills from the comfort of your home."
        />
        <meta property="og:url" content="https://muitonline.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://muitonline.com/assets/images/logo/logo.png" />
  
        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Organization",
              "name": "Maharishi University Online",
              "url": "https://muitonline.com/",
            },
            "author": {
              "@type": "Organization",
              "name": "Maharishi University Online",
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5",
              "worstRating": "1",
            },
            "reviewBody":
              "Maharishi University Online offers an outstanding range of courses with excellent faculty. The online platform is user-friendly and the course content is up-to-date and relevant. Highly recommended!",
          })}
        </script>
      </Helmet>
     <div className="rbt-breadcrumb-default rbt-breadcrumb-style-3">
        <div className="breadcrumb-inner">
            <img src="assets/images/bg/bg-image-10.jpg" alt="Education Images"/>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="content text-start">
                        <ul className="page-list">
                            <li className="rbt-breadcrumb-item"><a href="/">Home</a></li>
                            <li>
                                <div className="icon-right"><i className="feather-chevron-right"></i></div>
                            </li>
                            <li className="rbt-breadcrumb-item active">Yoga/Health</li>
                        </ul>
                        <h2 className="title">Certification in Yoga </h2>
                        <p className="description">Certification in Yoga validates proficiency in ancient techniques, postures, and philosophies, enhancing one's ability to guide individuals on a transformative journey to physical and mental well-being. It signifies a commitment to the holistic practice and fosters credibility in teaching yoga effectively.</p>

                        <div className="d-flex align-items-center mb--20 flex-wrap rbt-course-details-feature">

                            <div className="feature-sin best-seller-badge">
                                <span className="rbt-badge-2">
                                    <span className="image"><img src="assets/images/icons/card-icon-1.png"
                                            alt="Best Seller Icon"/></span> online learning platform
                                </span>
                            </div>

                            <div className="feature-sin rating">
                                <a href="#">4.3</a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                            </div>

                            <div className="feature-sin total-rating">
                                <a className="rbt-badge-4" href="#">475 rating</a>
                            </div>

                            <div className="feature-sin total-student">
                                <span>629 students</span>
                            </div>

                        </div>

                        <div className="rbt-author-meta mb--20">
                            <div className="rbt-avater">
                                <a href="#">
                                    <img src="assets/images/team/Pooja.PNG" alt=""/>
                                </a>
                            </div>
                            <div className="rbt-author-info">
                                By <a href="#">Pooja Kaushik</a> In  <a href="#">Yoga/Health</a>
                            </div>
                        </div>

                        <ul className="rbt-meta">
                            <li><i className="feather-calendar"></i>Last updated 12/2023</li>
                            <li><i className="feather-globe"></i>English</li>
                            <li><i className="feather-award"></i>Certified Course</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <Yogaoverview/>
    </>
  )
}

export default Yogabanner