import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Advancedigioverview from './Advancedigioverview';

const Advancedigibanner = () => {
    useEffect(() => {
      // Update the document title
      document.title = "Advanced Digital Marketing Course Online | Maharishi University Online";
  
      // Update meta description
      let descMeta = document.querySelector("meta[name='description']");
      if (!descMeta) {
        descMeta = document.createElement('meta');
        descMeta.name = 'description';
        document.head.appendChild(descMeta);
      }
      descMeta.setAttribute(
        'content',
        'Become an Advanced Digital Marketing Expert and start learning from fundamentals! Strong Advanced digital marketing online course fundamentals are significant to learn!'
      );
  
      // Update meta keywords
      let keywordsMeta = document.querySelector("meta[name='keywords']");
      if (!keywordsMeta) {
        keywordsMeta = document.createElement('meta');
        keywordsMeta.name = 'keywords';
        document.head.appendChild(keywordsMeta);
      }
      keywordsMeta.setAttribute(
        'content',
        'Advanced digital Marketing Course, advanced digital Marketing Course Online, advanced digital marketing, best advanced digital marketing course, fundamentals of advanced digital marketing, digital marketing course free, advanced digital marketing certification'
      );
    }, []);
  
    return (
      <>
        <Helmet>
        {/* Title */}
        <title>Advanced Digital Marketing Course Online | Maharishi University Online</title>
  
        {/* Meta Description */}
        <meta
          name="description"
          content="Become an Advanced Digital Marketing Expert and start learning from fundamentals! Strong Advanced digital marketing online course fundamentals are significant to learn!"
        />
  
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Advanced digital Marketing Course, advanced digital Marketing Course Online, advanced digital marketing, best advanced digital marketing course, fundamentals of advanced digital marketing, digital marketing course free, advanced digital marketing certification"
        />
  
        {/* Open Graph Tags */}
        <meta property="og:title" content="Advanced Digital Marketing Course Online | Maharishi University Online" />
        <meta
          property="og:description"
          content="Become an Advanced Digital Marketing Expert and start learning from fundamentals! Strong Advanced digital marketing online course fundamentals are significant to learn!"
        />
        <meta property="og:url" content="https://muitonline.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://muitonline.com/assets/images/logo/logo.png" />
  
        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Organization",
              "name": "Maharishi University Online",
              "url": "https://muitonline.com/",
            },
            "author": {
              "@type": "Organization",
              "name": "Maharishi University Online",
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5",
              "worstRating": "1",
            },
            "reviewBody":
              "Maharishi University Online offers an outstanding range of courses with excellent faculty. The online platform is user-friendly and the course content is up-to-date and relevant. Highly recommended!",
          })}
        </script>
      </Helmet>

    <div className="rbt-breadcrumb-default rbt-breadcrumb-style-3">
        <div className="breadcrumb-inner">
            <img src="assets/images/bg/bg-image-10.jpg" alt="Education Images"/>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="content text-start">
                        <ul className="page-list">
                            <li className="rbt-breadcrumb-item"><a href="/">Home</a></li>
                            <li>
                                <div className="icon-right"><i className="feather-chevron-right"></i></div>
                            </li>
                            <li className="rbt-breadcrumb-item active">Advanced digital marketing </li>
                        </ul>
                        <h2 className="title "> <span className='textred'>Be a Leader in  Digital Marketing</span></h2>
                        <p className="description">  Are you thinking about doing a Digital Marketing Course, So this course is best for you. Here you will get all core concepts of Digital marketing knowledge. In this Digital Marketing certification course, you will learn from industry experts.
                        </p>

                        <div className="d-flex align-items-center mb--20 flex-wrap rbt-course-details-feature">

                            <div className="feature-sin best-seller-badge">
                                <span className="rbt-badge-2">
                                    <span className="image"><img src="assets/images/icons/card-icon-1.png"
                                            alt="Best Seller Icon"/></span> online learning platform
                                </span>
                            </div>

                            <div className="feature-sin rating">
                                <a href="#">4.5</a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star"></i></a>
                                <a href="#"><i className="fa fa-star-half-o"></i></a>
                            </div>

                            <div className="feature-sin total-rating">
                                <a className="rbt-badge-4" href="#">13,475 rating</a>
                            </div>

                            <div className="feature-sin total-student">
                                <span>15,029 students</span>
                            </div>

                        </div>

                        <div className="rbt-author-meta mb--20">
                            <div className="rbt-avater">
                                <a href="#">
                                    <img src="assets/images/team/team-07.jpeg" alt="Tejasvita Goel"/>
                                </a>
                            </div>
                            <div className="rbt-author-info">
                                By <a href="#"> Pradeep Kumar Mishra</a> In  <a href="#">Advanced digital marketing</a>
                            </div>
                        </div>

                        <ul className="rbt-meta">
                            <li><i className="feather-calendar"></i>Last updated 07/2023</li>
                            <li><i className="feather-globe"></i>English/Hindi</li>
                            <li><i className="feather-award"></i>Certified Course</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <Advancedigioverview/>
    </>
  )
}

export default Advancedigibanner;
