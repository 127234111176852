import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="rbt-footer footer-style-1">
        <div className="footer-top">
          <div className="container">
            <div className="row row--15 mt_dec--30">
              <div className="col-lg-3 col-md-3 col-sm-6 col-12 mt--30">
                <div className="footer-widget">
                  <div className="logo">
                    <a href="/">
                      <img src="assets/images/logo/logo.png" alt="Edu-cause" />
                    </a>
                    <p>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.muit.muit_online&hl=en_US"
                        target="_blank"
                      >
                        {" "}
                        <img
                          src="assets/images/logo/googleplay.png"
                          className="img-fluid"
                          style={{ width: "150px", marginTop: "10px" }}
                        />
                      </a>
                    </p>
                  </div>

                  <div className="contact-btn mt--30">
                    <ul className="ft-link">
                      <li>
                        <span>Helpline No.:</span>{" "}
                        <a href="tel:+919599071023">+91 9599071023</a>
                      </li>
                      <li>
                        <span>E-mail:</span>{" "}
                        <a href="mailto:help@muitonline.com">
                          help@muitonline.com
                        </a>
                      </li>
                      <li>
                        <span>E-mail:</span>{" "}
                        <a href="mailto:hr@muitonline.com">hr@muitonline.com</a>
                      </li>
                    </ul>
                    <ul className="social-icon social-default icon-naked justify-content-start mt--20">
                      <li>
                        <a
                          href="https://www.facebook.com/muitonline"
                          target="_blank"
                        >
                          <i className="feather-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/muitonline"
                          target="_blank"
                        >
                          <i className="feather-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/muitonline/"
                          target="_blank"
                        >
                          <i className="feather-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/muitonlineofficial"
                          target="_blank"
                        >
                          <i className="feather-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCDIwqn215cIaN9WtGJfwarg"
                          target="_blank"
                        >
                          <i className="feather-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 col-12 mt--30">
                <div className="footer-widget">
                  <h5 className="ft-title">Marketing</h5>
                  <ul className="ft-link">
                    <li>
                      <Link to="advance-digital-marketing-course">
                        Advanced Certification in Digital Marketing
                      </Link>
                    </li>
                    <li>
                      <Link to="digital-marketing-course">
                        Fundamentals of Digital Marketing
                      </Link>
                    </li>
                    <li>
                      <Link to="Social-Media-Marketing">
                        Social Media Marketing Short-Term Course
                      </Link>
                    </li>
                    <li>
                      <Link to="Ai-in-digital-Marketing">
                        AI in Digital Marketing
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://apply.muitonline.com/wordpress-course"
                        target="_blank"
                      >
                        WordPress
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 col-12 mt--30">
                <div className="footer-widget">
                  <h5 className="ft-title">Animation</h5>
                  <ul className="ft-link">
                    <li>
                      <Link to="fundamental-of-3D-animation">
                        Fundamentals of 3D Animation
                      </Link>
                    </li>
                    <li>
                      <Link to="visual-development-concept-art-advanced">
                        Visual Development / Concept Art Advanced
                      </Link>
                    </li>
                    <li>
                      <Link to="motion-design-for-graphic-designers">
                        Motion design for graphic designers
                      </Link>
                    </li>
                    <li>
                      <Link to="Architecture-visualization">
                        Architecture Visualization
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12 mt--30">
                <div className="footer-widget">
                  <h5 className="ft-title ">Entrepreneurship</h5>
                  <ul className="ft-link">
                    <li>
                      <Link to="Entrepreneurship">
                        Entrepreneurship Certification Course
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-widget">
                  <h5 className="ft-title ">
                    <br />
                    <br />
                    Yoga/Health
                  </h5>
                  <ul className="ft-link">
                    <li>
                      <Link to="maharishi-diet-and-nutrition">
                        Diet & Nutrition Course
                      </Link>
                    </li>
                    <li>
                      <Link to="certification-in-maharishi-yoga">
                        Certification in Yoga
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="rbt-separator-mid">
        <div className="container">
          <hr className="rbt-separator m-0"></hr>
        </div>
      </div>

      {/* <!-- Start Copyright Area  --> */}
      <div className="copyright-area copyright-style-1 ptb--20 mb--30">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <p className="rbt-link-hover text-center text-lg-start">
                {currentYear}. Maharishi University Online, All Rights Reserved
              </p>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <ul className="copyright-link rbt-link-hover justify-content-center justify-content-lg-end mt_sm--10 mt_md--10">
                <li>
                  <a
                    href=" https://registration.muitonline.com/become-an-instructor"
                    target="_blank"
                  >
                    Become-an-instructor
                  </a>
                </li>
                <li>
                  <a
                    href=" http://registration.muitonline.com/FeePay"
                    target="_blank"
                  >
                    Pay Your Fee
                  </a>
                </li>
                <li>
                  <Link to="About">About Us</Link>
                </li>
                <li>
                  <Link to="Privacypolicy">Privacy policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Copyright Area  --> */}

      {/* <!-- End Page Wrapper Area --> */}
      <div className="rbt-progress-parent">
        <svg
          className="rbt-back-circle svg-inner"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>

      <div className="col-md-12">
        <div className="footer-fixed-bar">
          <a
            href="https://wa.me/919599071023"
            target="_blank"
            aria-label="Whatsup"
          >
            <i
              className="fa fa-whatsapp fa-fw"
              style={{ color: "#14D73F" }}
              aria-hidden="true"
            ></i>
            +91-9599071023
          </a>{" "}
          &nbsp;
          <a href="tel:+91-9711526942">
            <img
              src="assets/images/footer-phone.gif"
              width="16"
              height="25"
              alt="phone"
            />
            +91-9599071023
          </a>
          &nbsp;&nbsp;
          <p>#MUITONLINE</p>
          <a
            href="http://registration.muitonline.com/registration-form"
            target="_blank"
          >
            APPLY NOW
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
