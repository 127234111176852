import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const HomeBannerII = () => {
  useEffect(() => {
        // Update the document title
        document.title = "Maharishi University Online (MUIT Online) | Best Online Course";
    
        // Update meta description
        let descMeta = document.querySelector("meta[name='description']");
        if (!descMeta) {
          descMeta = document.createElement('meta');
          descMeta.name = 'description';
          document.head.appendChild(descMeta);
        }
        descMeta.setAttribute(
          'content',
          'Enroll in Maharishi University Online (MUIT Online), India’s best university, which provides the best online certification programs with 500+ expert faculty members. Best Online University For Online Digital Marketing Courses, Online 3d Animation Courses, Online Entrepreneurship courses, Diet and Nutrition, and Online Yoga Certification.'
        );
    
        // Update meta keywords
        let keywordsMeta = document.querySelector("meta[name='keywords']");
        if (!keywordsMeta) {
          keywordsMeta = document.createElement('meta');
          keywordsMeta.name = 'keywords';
          document.head.appendChild(keywordsMeta);
        }
        keywordsMeta.setAttribute(
          'content',
          'Maharishi University Online, MUIT Online, MUIT Online Certifications, MUIT Online Courses, MUIT Online University, MUIT Online Noida'
        );
      }, []);
  return (
    <>
    <Helmet>
      {/* Title */}
      <title>Maharishi University Online (MUIT Online) | Best Online Course</title>
  
  {/* Meta Description */}
  <meta
    name="description"
    content="Enroll in Maharishi University Online (MUIT Online), India’s best university, which provides the best online certification programs with 500+ expert faculty members. Best Online University For Online Digital Marketing Courses, Online 3d Animation Courses, Online Entrepreneurship courses, Diet and Nutrition, and Online Yoga Certification."
  />

  {/* Meta Keywords */}
  <meta
    name="keywords"
    content="Maharishi University Online, MUIT Online, MUIT Online Certifications, MUIT Online Courses, MUIT Online University, MUIT Online Noida"
  />

  {/* Open Graph Tags */}
  <meta property="og:title" content="Maharishi University Online (MUIT Online) | Best Online Course" />
  <meta
    property="og:description"
    content="Enroll in Maharishi University Online (MUIT Online), India’s best university, which provides the best online certification programs with 500+ expert faculty members. Best Online University For Online Digital Marketing Courses, Online 3d Animation Courses, Online Entrepreneurship courses, Diet and Nutrition, and Online Yoga Certification."
  />
  <meta property="og:url" content="https://muitonline.com/" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://muitonline.com/assets/images/logo/logo.png" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Organization",
              "name": "Maharishi University Online",
              "url": "https://muitonline.com/"
            },
            "author": {
              "@type": "Organization",
              "name": "Maharishi University Online"
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5",
              "worstRating": "1"
            },
            "reviewBody": "Maharishi University Online offers an outstanding range of courses with excellent faculty. The online platform is user-friendly and the course content is up-to-date and relevant. Highly recommended!"
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "EducationalOrganization",
            "name": "Maharishi University Online",
            "url": "https://muitonline.com/",
            "logo": "https://muitonline.com/assets/images/logo/logo.png",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+91-9599071023",
              "contactType": "customer service",
              "areaServed": "IN",
              "availableLanguage": "English"
            },
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Maharishi Nagar, Entry Gate No 4, opp. Sec 110, Noida",
              "addressLocality": "Noida",
              "addressRegion": "UP",
              "postalCode": "201304",
              "addressCountry": "IN"
            },
            "sameAs": [
              "https://www.facebook.com/muitonline",
              "https://twitter.com/muitonline",
              "https://www.instagram.com/muitonline",
              "https://in.linkedin.com/school/muitonlineofficial/"
            ]
          })}
        </script>
      </Helmet>
      <div
        className="rbt-banner-area rbt-banner-7 bg-gradient-1 theme-shape header-transperent-spacer"
        style={{ paddingTop: "20px" }}
      >
        <div className="wrapper w-100">
          <div className="container">
            <div className="row g-5 justify-content-between align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="content">
                  <div className="inner">
                    <div className="section-title text-start">
                      <span className="subtitle bg-primary-opacity">
                        🏆 The best online learning platform
                      </span>
                    </div>
                    <h1 className="title">
                      Innovative{" "}
                      <span className="theme-gradient">online Academic</span>{" "}
                      Platform for Your Career.
                    </h1>
                    <div className="rbt-like-total">
                      <div className="profile-share">
                        <a
                          href="#"
                          className="avatar"
                          data-tooltip="Disha"
                          tabindex="0"
                        >
                          <img
                            src="assets/images/testimonial/client-04.png"
                            alt="education"
                          />
                        </a>
                        <a
                          href="#"
                          className="avatar"
                          data-tooltip="Saurabh"
                          tabindex="0"
                        >
                          <img
                            src="assets/images/testimonial/client-05.png"
                            alt="education"
                          />
                        </a>
                        <a
                          href="#"
                          className="avatar"
                          data-tooltip="Mayank"
                          tabindex="0"
                        >
                          <img
                            src="assets/images/testimonial/client-06.png"
                            alt="education"
                          />
                        </a>
                        <div className="more-author-text">
                          <h5 className="total-join-students">
                            Join Over 3000+ Students
                          </h5>
                          <p className="subtitle">
                            Have a new ideas every week.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="rbt-button-group justify-content-start mt--30">
                      <a
                        className="rbt-btn btn-border rbt-switch-btn"
                        href="https://registration.muitonline.com"
                        target="_blank"
                      >
                        <span data-text="Sign Up Now">Sign Up Now</span>
                      </a>
                      <Link
                        className="rbt-btn btn-gradient rbt-switch-btn"
                        to="all-course"
                      >
                        <span data-text="Find Courses">Find Courses</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail-wrapper">
                  <div className="thumbnail text-end">
                    <img
                      src="../assets/images/banner/muit_banner - Copy.png"
                      alt="Education Images"
                    />
                  </div>
                  <div className="card-info bounce-slide">
                    <div className="inner">
                      <div className="name">
                        Sumit Srivastava <span>/ IND</span>
                      </div>
                      <div className="rating-wrapper d-block d-sm-flex">
                        <div className="rating">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                        <span>(Google Review)</span>
                      </div>
                    </div>
                    <div className="notify-icon">
                      <img
                        src="assets/images/testimonial/T2.png"
                        alt="Client Images"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBannerII;
