import React from "react";

const Homecounterup = () => {
  return (
    <>
      {/* <!-- Start Counterup Area  --> */}
      <div className="rbt-counterup-area bg-color-extra2 rbt-section-gapBottom default-callto-action-overlap rbt-section-gapTop">
        <div className="container">
          <div className="row mb--60">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="subtitle bg-primary-opacity">
                  Why Choose Us
                </span>
                <h2 className="title">
                  Creating A Community Of <br /> Life Long Learners.
                </h2>
              </div>
            </div>
          </div>
          <div className="row g-5 hanger-line">
            {/* <!-- Start Single Counter  --> */}
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="rbt-counterup rbt-hover-03 border-bottom-gradient">
                <div className="top-circle-shape"></div>
                <div className="inner">
                  <div className="rbt-round-icon">
                    <img
                      src="assets/images/icons/counter-01.png"
                      alt="Icons Images"
                    />
                  </div>
                  <div className="content">
                    <h3 className="counter">
                      <span className="odometer" data-count="125">
                        125 
                      </span>
                    </h3>
                    <span className="subtitle">Learners &amp; counting</span>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End Single Counter  --> */}

            {/* <!-- Start Single Counter  --> */}
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--60 mt_md--30 mt_sm--30 mt_mobile--60">
              <div className="rbt-counterup rbt-hover-03 border-bottom-gradient">
                <div className="top-circle-shape"></div>
                <div className="inner">
                  <div className="rbt-round-icon">
                    <img
                      src="assets/images/icons/counter-02.png"
                      alt="Icons Images"
                    />
                  </div>
                  <div className="content">
                    <h3 className="counter">
                      <span className="odometer" data-count="12">
                        12
                      </span>
                    </h3>
                    <span className="subtitle">Courses & Video</span>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End Single Counter  --> */}

            {/* <!-- Start Single Counter  --> */}
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--60 mt_sm--60">
              <div className="rbt-counterup rbt-hover-03 border-bottom-gradient">
                <div className="top-circle-shape"></div>
                <div className="inner">
                  <div className="rbt-round-icon">
                    <img
                      src="assets/images/icons/counter-03.png"
                      alt="Icons Images"
                    />
                  </div>
                  <div className="content">
                    <h3 className="counter">
                      <span className="odometer" data-count="1556">
                        1556
                      </span>
                    </h3>
                    <span className="subtitle">Certified Students</span>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End Single Counter  --> */}

            {/* <!-- Start Single Counter  --> */}
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--60 mt_md--30 mt_sm--30 mt_mobile--60">
              <div className="rbt-counterup rbt-hover-03 border-bottom-gradient">
                <div className="top-circle-shape"></div>
                <div className="inner">
                  <div className="rbt-round-icon">
                    <img
                      src="assets/images/icons/counter-04.png"
                      alt="Icons Images"
                    />
                  </div>
                  <div className="content">
                    <h3 className="counter">
                      <span className="odometer" data-count="1890">
                        1890
                      </span>
                    </h3>
                    <span className="subtitle">Registered Enrolls</span>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End Single Counter  --> */}
          </div>
        </div>
      </div>
      {/* <!-- End Counterup Area  --> */}
    </>
  );
};

export default Homecounterup;
